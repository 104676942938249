import React from "react";
import { useNavigate } from 'react-router-dom';

export const Services = (props) => {
  const navigate = useNavigate();

  const handle = (route) => {
    // Perform some logic and then navigate to the Home page
    navigate('/' + route + '#cards-title');
  };

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Nos Services</h2>
          <p>
            Nous vous proposons un large éventail de formations, dispensées par des professionnels ayant des années d'expérience, et une mise à jour constante de leurs acquis, au regard des évolutions des métiers.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div
                    onClick={() => handle(d.title)}
                    key={`${d.title}-${i}`}
                    id={`service-elem-${d.title}-${i}`}
                    className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.title}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};