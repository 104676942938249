import React from "react";

export const Contact = (props) => {

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Prenez contact</h2>
                <div className="contact-info">
                  <div className="contact-item">
                    <p>
                      <span>
                        <i className="fa fa-map-marker"></i> Adresse
                      </span>
                      {props.data ? props.data.address : "loading"}
                    </p>
                  </div>
                  <div className="contact-item">
                    <p>
                      <span>
                        <i className="fa fa-pencil-square-o"></i> Déclaration d'activité
                      </span>
                      {props.data ? props.data.noActivite : "loading"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info" style={{fontSize: "1.5em"}}>
            <div className="contact-item">
              <div>
                <span>
                  <i className="fa fa-pencil-square-o"></i> Siret
                </span>
                {props.data ? props.data.siret : "loading"}
              </div>
            </div>
            <div className="contact-item">
              <div>
                <span>
                  <i className="fa fa-phone"></i> Téléphone
                </span>{" "}
                <ul>
                  {props.data
                      ? props.data.phones.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                      ))
                      : "loading"}
                </ul>
              </div>
            </div>
            <div className="contact-item">
              <div>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                <ul>
                {props.data
                      ? props.data.emails.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                      ))
                      : "loading"}
                </ul>
              </div>
            </div>
          </div>
          {/*<div className="col-md-12">*/}
          {/*  <div className="row">*/}
          {/*    <div className="social">*/}
          {/*      <ul>*/}
          {/*        <li>*/}
          {/*          <a href={props.data ? props.data.facebook : "/"}>*/}
          {/*            <i className="fa fa-facebook"></i>*/}
          {/*          </a>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <a href={props.data ? props.data.twitter : "/"}>*/}
          {/*            <i className="fa fa-twitter"></i>*/}
          {/*          </a>*/}
          {/*        </li>*/}
          {/*        <li>*/}
          {/*          <a href={props.data ? props.data.youtube : "/"}>*/}
          {/*            <i className="fa fa-youtube"></i>*/}
          {/*          </a>*/}
          {/*        </li>*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; {(new Date()).getUTCFullYear()} - NJR Conseil - Formation - Audit{" "}
          </p>
        </div>
      </div>
    </div>
  );
};